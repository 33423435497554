import { AxiosResponse } from 'axios';
import { TAuthResponse } from 'models/AuthResponse';
import { TAvatar, TFio, TPhone } from 'models/UserResponse';
import { TCityResponse, TCityItemsResponse, TItemsTelemetryResponse, TFeatureCollection } from 'models/CityResponse';
import {
  TRTsDetailResponse,
  TImgResponse,
  TTelemetryResponse,
  TPhasesResponse,
  TCumulativeDataResponse,
  TEventsResponse,
  TSetProgram,
  TSegmentsLastHourResponse,
} from 'models/CrossroadResponse';
import { TArchiveResponse, TDescript } from 'models/ArchiveResponse';
import { TUdsResponse, TUdss } from 'models/UdsResponse';
import { TCommentResponse, TComments } from 'models/CommentResponse';
// Общие компоненты
// Локальные компоненты
import { instancePrivate, instancePublic } from './instances';

export const authAPI = {
  async login(username: string, password: string): Promise<AxiosResponse<TAuthResponse>> {
    return instancePublic.post<TAuthResponse>('/auth/login', {
      email: username,
      password,
    });
  },
  async checkAuth(): Promise<AxiosResponse<TAuthResponse>> {
    return instancePrivate.get<TAuthResponse>('/auth/check', {});
  },
};

export const userAPI = {
  async avatar(): Promise<AxiosResponse<TAvatar>> {
    return instancePrivate.get<TAvatar>('/user/get_avatar', {});
  },
  async fio(fio: string): Promise<AxiosResponse<TFio>> {
    return instancePrivate.post<TFio>('/user/edit_fio', {
      fio,
    });
  },
  async phone(phone: string): Promise<AxiosResponse<TPhone>> {
    return instancePrivate.post<TPhone>('/user/edit_phone', {
      phone,
    });
  },
  async edit_avatar(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('/user/edit_avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const cityAPI = {
  async detail(): Promise<AxiosResponse<TCityResponse>> {
    return instancePrivate.get<TCityResponse>('/city/detail', {});
  },
  async itemsTelemetry(): Promise<AxiosResponse<TCityItemsResponse<TItemsTelemetryResponse>>> {
    return instancePrivate.get<TCityItemsResponse<TItemsTelemetryResponse>>('/city/items_telemetry', {});
  },
  async transport(): Promise<AxiosResponse<TFeatureCollection>> {
    return instancePrivate.get<TFeatureCollection>('/city/transport_situation', {});
  },
};

export const crossroadAPI = {
  detailRTs(id: string): Promise<AxiosResponse<TRTsDetailResponse>> {
    return instancePrivate.get<TRTsDetailResponse>(`/crossroad/rts/${id}/detail`, {});
  },
  img(id: string): Promise<AxiosResponse<TImgResponse>> {
    return instancePrivate.get<TImgResponse>(`/crossroad/rts/${id}/img`, {});
  },
  stopVideo(id: string, idVprocessor: string): Promise<AxiosResponse<string>> {
    return instancePrivate.post<string>(`/crossroad/rts/${id}/${idVprocessor}/stop_video`, {});
  },
  telemetryRTs(id: string): Promise<AxiosResponse<TTelemetryResponse>> {
    return instancePrivate.get<TTelemetryResponse>(`/crossroad/rts/${id}/telemetry`, {});
  },
  events(id: string, utime_start: number, utime_end: number): Promise<AxiosResponse<TEventsResponse>> {
    return instancePrivate.post<TEventsResponse>(`/crossroad/${id}/events`, {
      utime_start,
      utime_end,
    });
  },
  eventsAll(id: string, utime_start: number, utime_end: number): Promise<AxiosResponse<TEventsResponse>> {
    return instancePrivate.post<TEventsResponse>(`/crossroad/${id}/events_all`, {
      utime_start,
      utime_end,
    });
  },
  crossroadRating(): Promise<AxiosResponse<TCumulativeDataResponse>> {
    return instancePrivate.get<TCumulativeDataResponse>('/crossroad/kts/crossroad/rating', {});
  },
  streetRating(): Promise<AxiosResponse<TCumulativeDataResponse>> {
    return instancePrivate.get<TCumulativeDataResponse>('/crossroad/kts/street/rating', {});
  },
  soHoursByCross(id: string, startUTime: number, endUTime: number): Promise<AxiosResponse<TCumulativeDataResponse>> {
    return instancePrivate.post<TCumulativeDataResponse>(`/crossroad/rts/${id}/so_hours`, {
      utime_start: startUTime,
      utime_end: endUTime,
    });
  },
  ktsHoursByCross(id: string, startUTime: number, endUTime: number): Promise<AxiosResponse<TCumulativeDataResponse>> {
    return instancePrivate.post<TCumulativeDataResponse>(`/crossroad/rts/${id}/kts_hours`, {
      utime_start: startUTime,
      utime_end: endUTime,
    });
  },
  ktsLastHourBySegments(id: string, shiftHour = 0): Promise<AxiosResponse<TSegmentsLastHourResponse>> {
    return instancePrivate.post<TSegmentsLastHourResponse>(`/crossroad/rts/${id}/kts/last_hour`, {
      shift_hour: shiftHour,
    });
  },
  setProgramRts(id: string, program: string): Promise<AxiosResponse<TSetProgram>> {
    return instancePrivate.post<TSetProgram>(`/crossroad/rts/${id}/set_program`, {
      oID: id,
      program,
    });
  },
  setCustomProgramRts(id: string, sequence: string[], durations: string[]): Promise<AxiosResponse<TSetProgram>> {
    return instancePrivate.post<TSetProgram>(`/crossroad/rts/${id}/set_custom_program`, {
      oID: id,
      sequence,
      durations,
    });
  },
  setCustomUFProgramRts(id: string, phase: number): Promise<AxiosResponse<TSetProgram>> {
    return instancePrivate.post<TSetProgram>(`/crossroad/rts/${id}/set_uf_program`, {
      oID: id,
      phase,
    });
  },
  rts_phases_detail(id: string): Promise<AxiosResponse<TPhasesResponse>> {
    return instancePrivate.get<TPhasesResponse>(`/crossroad/rts/${id}/phases`, {});
  },

  async day_data(id: string, utime: number): Promise<AxiosResponse<Blob>> {
    return instancePrivate.post<Blob>(
      `/crossroad/rts/${id}/day_reports`,
      {
        utime,
      },
      {
        responseType: 'blob',
      },
    );
  },
  async data_reports(id: string, utime_start: number, utime_end: number): Promise<AxiosResponse<Blob>> {
    return instancePrivate.post<Blob>(
      `/crossroad/rts/${id}/data_reports`,
      {
        utime_start,
        utime_end,
      },
      {
        responseType: 'blob',
      },
    );
  },
  async all_reports(id: string, utime_start: number, utime_end: number): Promise<AxiosResponse<Blob>> {
    return instancePrivate.post<Blob>(
      `/crossroad/rts/${id}/all_reports`,
      {
        utime_start,
        utime_end,
      },
      {
        responseType: 'blob',
      },
    );
  },
  async difference_reports(
    id: string,
    utime_start: number,
    utime_end: number,
    utime_start_two: number,
    utime_end_two: number,
  ): Promise<AxiosResponse<Blob>> {
    return instancePrivate.post<Blob>(
      `/crossroad/rts/${id}/difference_reports`,
      {
        utime_start,
        utime_end,
        utime_start_two,
        utime_end_two,
      },
      {
        responseType: 'blob',
      },
    );
  },
};

export const utilAPI = {
  async ping(): Promise<AxiosResponse<null>> {
    return instancePublic.get<null>('/utils/ping', {});
  },
};

//  Здесь и далее идет создание REST запросов для раздела Архив на сайте и его подразделов

export const getTableAPI = {
  async detail(): Promise<AxiosResponse<TArchiveResponse>> {
    return instancePrivate.get<TArchiveResponse>('/archive/podd', {});
  },
};

export const getReportsAPI = {
  async detail(): Promise<AxiosResponse<TArchiveResponse>> {
    return instancePrivate.get<TArchiveResponse>('/archive/reports', {});
  },
};

export const getProjectsAPI = {
  async detail(): Promise<AxiosResponse<TArchiveResponse>> {
    return instancePrivate.get<TArchiveResponse>('/archive/projects', {});
  },
};

export const getSchemesAPI = {
  async detail(): Promise<AxiosResponse<TArchiveResponse>> {
    return instancePrivate.get<TArchiveResponse>('/archive/schemes', {});
  },
};

export const getLettersAPI = {
  async detail(): Promise<AxiosResponse<TArchiveResponse>> {
    return instancePrivate.get<TArchiveResponse>('/archive/letters', {});
  },
};

export const getTechsAPI = {
  async detail(): Promise<AxiosResponse<TArchiveResponse>> {
    return instancePrivate.get<TArchiveResponse>('/archive/techs', {});
  },
};

export const postTableAPI = {
  async post_data(
    name_object: string,
    target_object: string,
    file_description: string,
    file_name: string,
    time: string,
    comment: string,
  ): Promise<AxiosResponse<TDescript>> {
    return instancePrivate.post<TDescript>('/archive/podd', {
      name_object,
      target_object,
      file_description,
      file_name,
      time,
      comment,
    });
  },
};

export const postReportsAPI = {
  async post_data(
    name_object: string,
    target_object: string,
    file_description: string,
    file_name: string,
    time: string,
    comment: string,
  ): Promise<AxiosResponse<TDescript>> {
    return instancePrivate.post<TDescript>('/archive/reports', {
      name_object,
      target_object,
      file_description,
      file_name,
      time,
      comment,
    });
  },
};

export const postProjectsAPI = {
  async post_data(
    name_object: string,
    target_object: string,
    file_description: string,
    file_name: string,
    time: string,
    comment: string,
  ): Promise<AxiosResponse<TDescript>> {
    return instancePrivate.post<TDescript>('/archive/projects', {
      name_object,
      target_object,
      file_description,
      file_name,
      time,
      comment,
    });
  },
};

export const postLettersAPI = {
  async post_data(
    name_object: string,
    target_object: string,
    file_description: string,
    file_name: string,
    time: string,
    comment: string,
  ): Promise<AxiosResponse<TDescript>> {
    return instancePrivate.post<TDescript>('/archive/letters', {
      name_object,
      target_object,
      file_description,
      file_name,
      time,
      comment,
    });
  },
};

export const postTechsAPI = {
  async post_data(
    name_object: string,
    target_object: string,
    file_description: string,
    file_name: string,
    time: string,
    comment: string,
  ): Promise<AxiosResponse<TDescript>> {
    return instancePrivate.post<TDescript>('/archive/techs', {
      name_object,
      target_object,
      file_description,
      file_name,
      time,
      comment,
    });
  },
};

export const putCommentTableAPI = {
  async put_comment(
    id: number,
    name_object: string,
    target_object: string,
    file_description: string,
    file_name: string,
    time: string,
    comment: string,
  ): Promise<AxiosResponse<TDescript>> {
    return instancePrivate.put<TDescript>(`/archive/podd_comment/${id}`, {
      name_object,
      target_object,
      file_description,
      file_name,
      time,
      comment,
    });
  },
};

export const putCommentReportsAPI = {
  async put_comment(
    id: number,
    name_object: string,
    target_object: string,
    file_description: string,
    file_name: string,
    time: string,
    comment: string,
  ): Promise<AxiosResponse<TDescript>> {
    return instancePrivate.put<TDescript>(`/archive/reports_comment/${id}`, {
      name_object,
      target_object,
      file_description,
      file_name,
      time,
      comment,
    });
  },
};

export const putCommentProjectsAPI = {
  async put_comment(
    id: number,
    name_object: string,
    target_object: string,
    file_description: string,
    file_name: string,
    time: string,
    comment: string,
  ): Promise<AxiosResponse<TDescript>> {
    return instancePrivate.put<TDescript>(`/archive/projects_comment/${id}`, {
      name_object,
      target_object,
      file_description,
      file_name,
      time,
      comment,
    });
  },
};

export const putCommentLettersAPI = {
  async put_comment(
    id: number,
    name_object: string,
    target_object: string,
    file_description: string,
    file_name: string,
    time: string,
    comment: string,
  ): Promise<AxiosResponse<TDescript>> {
    return instancePrivate.put<TDescript>(`/archive/letters_comment/${id}`, {
      name_object,
      target_object,
      file_description,
      file_name,
      time,
      comment,
    });
  },
};

export const putCommentTechsAPI = {
  async put_comment(
    id: number,
    name_object: string,
    target_object: string,
    file_description: string,
    file_name: string,
    time: string,
    comment: string,
  ): Promise<AxiosResponse<TDescript>> {
    return instancePrivate.put<TDescript>(`/archive/techs_comment/${id}`, {
      name_object,
      target_object,
      file_description,
      file_name,
      time,
      comment,
    });
  },
};

export const fileAPI = {
  async send(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('archive/file_podd/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const fileReportsAPI = {
  async send(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('archive/file_reports/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const fileProjectsAPI = {
  async send(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('archive/file_projects/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const fileLettersAPI = {
  async send(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('archive/file_letters/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const fileTechsAPI = {
  async send(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('archive/file_techs/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const updateAPI = {
  async send(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('archive/update_file/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const updateReportsAPI = {
  async send(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('archive/update_file_reports/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const updateProjectsAPI = {
  async send(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('archive/update_file_projects/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const updateLettersAPI = {
  async send(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('archive/update_file_letters/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const updateTechsAPI = {
  async send(fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>('archive/update_file_techs/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const downloadAPI = {
  async download(filename: string): Promise<AxiosResponse<File>> {
    return instancePrivate.get<File>(`/archive/download_podd/${filename}`, {
      responseType: 'blob',
    });
  },
};

export const downloadReportsAPI = {
  async download(filename: string): Promise<AxiosResponse<File>> {
    return instancePrivate.get<File>(`/archive/download_reports/${filename}`, {
      responseType: 'blob',
    });
  },
};

export const downloadProjectsAPI = {
  async download(filename: string): Promise<AxiosResponse<File>> {
    return instancePrivate.get<File>(`/archive/download_projects/${filename}`, {
      responseType: 'blob',
    });
  },
};

export const downloadLettersAPI = {
  async download(filename: string): Promise<AxiosResponse<File>> {
    return instancePrivate.get<File>(`/archive/download_letters/${filename}`, {
      responseType: 'blob',
    });
  },
};

export const downloadTechsAPI = {
  async download(filename: string): Promise<AxiosResponse<File>> {
    return instancePrivate.get<File>(`/archive/download_techs/${filename}`, {
      responseType: 'blob',
    });
  },
};
// Раздел Архив закончен

//  Здесь и далее идет создание REST запросов для раздела УДС на сайте и его подразделов

export const getUdsAPI = {
  async detail(): Promise<AxiosResponse<TUdsResponse>> {
    return instancePrivate.get<TUdsResponse>('/exploitation/uds', {});
  },
};

export const getUdsAllAPI = {
  async detail(): Promise<AxiosResponse<TUdsResponse>> {
    return instancePrivate.get<TUdsResponse>('/exploitation/uds_all', {});
  },
};

export const postUdsAPI = {
  async post_data(
    author: string,
    themes: string,
    executor: string,
    description: string,
    latitude: number,
    longitude: number,
    create_time: string,
    work_time: string,
    task_time: string,
    do_time: string,
    close_time: string,
  ): Promise<AxiosResponse<TUdss>> {
    return instancePrivate.post<TUdss>('/exploitation/uds', {
      author,
      themes,
      executor,
      description,
      latitude,
      longitude,
      create_time,
      work_time,
      task_time,
      do_time,
      close_time,
    });
  },
};

export const putUdsDescriptionAPI = {
  async put_description(
    id: number,
    author: string,
    themes: string,
    executor: string,
    description: string,
    latitude: number,
    longitude: number,
    create_time: string,
    work_time: string,
    task_time: string,
    do_time: string,
    close_time: string,
  ): Promise<AxiosResponse<TUdss>> {
    return instancePrivate.put<TUdss>(`/exploitation/uds_name/${id}`, {
      author,
      themes,
      executor,
      description,
      latitude,
      longitude,
      create_time,
      work_time,
      task_time,
      do_time,
      close_time,
    });
  },
};

export const putUdsWorkAPI = {
  async put_description(
    id: number,
    author: string,
    themes: string,
    executor: string,
    description: string,
    latitude: number,
    longitude: number,
    create_time: string,
    work_time: string,
    task_time: string,
    do_time: string,
    close_time: string,
  ): Promise<AxiosResponse<TUdss>> {
    return instancePrivate.put<TUdss>(`/exploitation/uds_work/${id}`, {
      author,
      themes,
      executor,
      description,
      latitude,
      longitude,
      create_time,
      work_time,
      task_time,
      do_time,
      close_time,
    });
  },
};

export const putUdsDoAPI = {
  async put_description(
    id: number,
    author: string,
    themes: string,
    executor: string,
    description: string,
    latitude: number,
    longitude: number,
    create_time: string,
    work_time: string,
    task_time: string,
    do_time: string,
    close_time: string,
  ): Promise<AxiosResponse<TUdss>> {
    return instancePrivate.put<TUdss>(`/exploitation/uds_do/${id}`, {
      author,
      themes,
      executor,
      description,
      latitude,
      longitude,
      create_time,
      work_time,
      task_time,
      do_time,
      close_time,
    });
  },
};

export const putUdsCloseAPI = {
  async put_description(
    id: number,
    author: string,
    themes: string,
    executor: string,
    description: string,
    latitude: number,
    longitude: number,
    create_time: string,
    work_time: string,
    task_time: string,
    do_time: string,
    close_time: string,
  ): Promise<AxiosResponse<TUdss>> {
    return instancePrivate.put<TUdss>(`/exploitation/uds_close/${id}`, {
      author,
      themes,
      executor,
      description,
      latitude,
      longitude,
      create_time,
      work_time,
      task_time,
      do_time,
      close_time,
    });
  },
};

export const getCommentAPI = {
  async detail(id: number): Promise<AxiosResponse<TCommentResponse>> {
    return instancePrivate.get<TCommentResponse>(`/exploitation/comment/${id}`, { params: { data_id: id } });
  },
};

export const postCommentAPI = {
  async post_data(id: number, author: string, description: string, times: string): Promise<AxiosResponse<TComments>> {
    return instancePrivate.post<TComments>(`/exploitation/comment/${id}`, {
      author,
      description,
      times,
    });
  },
};

export const udsFileAPI = {
  async send(id: number, fileUpload: File | null): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    if (fileUpload) {
      formData.append('file', fileUpload);
    }
    return instancePrivate.post<File>(`/exploitation/uds_photo/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

// export const photoAPI = {
//   async photo(id: number): Promise<AxiosResponse<Blob>> {
//     return instancePrivate.get<Blob>(`/exploitation/uds_photo/${id}`, {
//       responseType: 'blob',
//     });
//   },
// };

export const photoAPI = {
  async photo(id: number): Promise<AxiosResponse<string[]>> {
    return instancePrivate.get<string[]>(`/exploitation/uds_photo/${id}`, {});
  },
};
// Раздел УДС закончен

// Мессенджер
export const messengerAPI = {
  async validateZulipAccount(email: string, password: string): Promise<AxiosResponse> {
    return instancePrivate.post('messenger/validate/account', {
      email,
      password,
    });
  },
  async sendMessage(text: string): Promise<AxiosResponse> {
    return instancePrivate.post('/messenger/send/message', {
      text,
    });
  },
  async getMessages(): Promise<AxiosResponse> {
    return instancePrivate.get('/messenger/get/messages', {});
  },
};
